<template>
  <div>
    <!-- lead-search -->
    <div
      class="lead-search-area"
      v-if="$storage.get('auth').user.user_type != 2"
    >
      <div class="row">
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="lead-search">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Agent</label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="filterSearch.agent"
                v-on:change="updateData('agent')"
              >
                <option value="">Select Agent</option>
                <option
                  v-for="emp in allEmployees"
                  :key="emp.id"
                  :value="emp.id"
                >
                  {{ emp.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="lead-search">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Teams</label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="filterSearch.team"
                v-on:change="updateData('team')"
              >
                <option value="">Select Team</option>
                <option
                  v-for="team in allTeams"
                  :key="team.id"
                  :value="team.tm_id"
                >
                  {{ team.tm_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="lead-search">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Status</label>

              <select
                class="form-control"
                v-model="filterSearch.status"
                v-on:change="updateData('status')"
              >
                <option value="" selected>Select Status</option>
                <option value="all">Created</option>
                <option value="0">Scheduled</option>
                <option value="1">Shown</option>
                <option value="2">Missed</option>
                <option value="3">Confirmed</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- lead-search -->

    <!-- leads-table-area  -->
    <div class="leads-table-area task-table">
      <div class="timeline">
        <button
          type="button"
          v-bind:class="$store.state.appointFilter == 'today' ? 'current' : ''"
          v-on:click="updateData('today')"
        >
          <span class="today">Today</span>
        </button>
        <button
          type="button"
          v-bind:class="$store.state.appointFilter == 'month' ? 'current' : ''"
          v-on:click="updateData('month')"
        >
          <span class="today">This Month</span>
        </button>
        <button
          type="button"
          v-bind:class="$store.state.appointFilter == 'custom' ? 'current' : ''"
        >
          <date-range-picker
            :opens="'left'"
            :ranges="false"
            :locale-data="{ format: 'yyyy-mm-dd' }"
            v-model="filterSearch.customDate"
            @update="updateData('custom')"
          ></date-range-picker
          ><span class="month">Custom Date</span>
        </button>
      </div>
      <table class="display" id="appt_table">
        <thead>
          <tr>
            <th>Leadsheet ID</th>
            <th>Appt Date</th>
            <th>Appt Time</th>
            <th>Customer Name</th>
            <th>In Showroom</th>
            <th>Sales Rep</th>
            <th>BDC Rep</th>
            <th>Creation Date</th>
            <th>Status</th>

            <!-- <th>Leadsheet #</th>
            <th>Customer Name</th>
            <th>Sales Rep</th>
            <th>BDC Rep</th>
            <th>Appt Date</th>
            <th>Appt Time</th>
            <th>Status</th> -->
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- leads-table-area  -->

    <!-- appointment  deatils Modal -->
    <div
      class="modal fade"
      id="appointmentDetail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vehicleDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <a class="delas-colse close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
          <div class="modal-body Intersets-modal">
            <div class="row">
              <div class="col-12">
                <div class="details-modal">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Description</td>
                        <td v-html="appointment_detail.a_desc"></td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>{{ appointment_detail.a_date }}</td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>{{ appointment_detail.a_time }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled By</td>
                        <td>{{ appointment_detail.a_by }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled For</td>
                        <td>{{ appointment_detail.a_for }}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{{ appointment_detail.a_status }}</td>
                      </tr>
                      <tr>
                        <td>Lead Id</td>
                        <td>
                          <a
                            href="#"
                            v-on:click.stop.prevent="
                              redirectIt(appointment_detail.ap_lead_id)
                            "
                          >
                            {{ appointment_detail.ap_lead_id }}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Created Date</td>
                        <td>{{ appointment_detail.created_date }}</td>
                      </tr>
                      <br />
                      <div class="row gotolead">
                        <a
                          href="#"
                          v-on:click.stop.prevent="
                            redirectIt(appointment_detail.ap_lead_id)
                          "
                          class="rem-btn"
                          ><span> Go to lead</span></a
                        >
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- <div class="col-12 text-center mt-3">
                  <button class="rem-btn" style="display: initial">Select</button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- appointment  deatils Modal  -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  name: "AllAppointments",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      appts: null,
      filterSearch: {
        duration: "month",
        agent: "",
        team: "",
        customDate: {},
        from: "",
        to: "",
        status: "",
      },
      appointment_detail: {},
      allEmployees: [],
      allTeams: [],
    };
  },
  created() {
    if (this.$storage.get("auth").user.user_type != 2) {
      this.getAllEmployees();
      this.getAllTeams();
    }
    if (
      this.$route.query.duration != undefined &&
      this.$route.query.duration != null &&
      this.$route.query.duration != "undefined" &&
      this.$route.query.duration != ""
    ) {
      this.filterSearch.duration = this.$route.query.duration;
    }
    if (
      this.$route.query.status != undefined &&
      this.$route.query.status != null &&
      this.$route.query.status != "undefined" &&
      this.$route.query.status != ""
    ) {
      this.filterSearch.status = 0;
    }
  },
  methods: {
    redirectIt(id) {
      $("#appointmentDetail").modal("hide");
      setTimeout(() => {
        this.$router.push("/lead-detail/" + id);
      }, 400);
    },
    apptDetail(id) {
      this.redirectIt(id);
      // axios
      //   .get("/appointment/detail/" + id, {
      //     headers: {
      //       Authorization: "Bearer " + this.$storage.get("auth").token,
      //     },
      //   })
      //   .then((res) => {
      //     const data = res.data;
      //     if (data.success) {
      //       this.appointment_detail = data.success;
      //     }
      //     $("#appointmentDetail").modal("show");
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    getAllEmployees() {
      axios
        .get("all_employees", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.allEmployees = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllTeams() {
      axios
        .get("teams", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.allTeams = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateData(val) {
      if (val == "agent") {
        this.filterSearch.team = "";
      } else if (val == "team") {
        this.filterSearch.agent = "";
      } else if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.$store.state.appoint_from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.$store.state.appoint_to = moment(new Date(endDate)).format(
          "YYYY-MM-DD"
        );

        this.$store.commit("updateAppointFilter", "custom");
      } else if (val == "today") {
        this.$store.commit("updateAppointFilter", "today");
      } else if (val == "month") {
        this.$store.commit("updateAppointFilter", "month");
      }
      $("#appt_table").dataTable().fnDraw();
    },
  },
  mounted() {
    let v = this;
    if (v.$route.query.type) {
      if (v.$route.query.type == "created") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = "all";
      }
      if (v.$route.query.type == "missed") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = 2;
      }
      if (v.$route.query.type == "shown") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = 1;
      }
      if (v.$route.query.type == "confirmed") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = 3;
      }
      if (v.$route.query.type == "scheduled") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = 0;
      }
    }
    $("#appt_table")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        stateSave: true,
        order: [[1, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "appointments_list",
          type: "POST",
          data: function (d) {
            d.agent = v.filterSearch.agent;
            d.tm_id = v.filterSearch.team;
            d.duration = v.$store.state.appointFilter;
            d.from = v.$store.state.appoint_from;
            d.to = v.$store.state.appoint_to;
            d.status = v.filterSearch.status;
            //console.log(d)
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
        columnDefs: [
          {
            targets: [3, 4, 6],
            orderable: false,
          },
        ],
      });

    $("#appt_table").on("click", "tr", function () {
      var id = $(this).find(".apptdetail").data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        v.apptDetail(id);
      }
    });

    if (this.$route.query.type) {
      if (this.$route.query.type == "created") {
        v.filterSearch.status = "all";
      }
      if (this.$route.query.type == "missed") {
        v.filterSearch.status = 2;
      }
      if (this.$route.query.type == "shown") {
        v.filterSearch.status = 1;
      }
      if (this.$route.query.type == "confirmed") {
        v.filterSearch.status = 3;
      }
      if (this.$route.query.type == "scheduled") {
        v.filterSearch.status = 0;
      }
    }
  },
};
</script>
<style scoped>
@import "../../assets/css/dataTables.css";
.task-table .timeline,
.addcustomer-table .timeline {
  margin-top: 103px;
  position: relative;
  top: 15px;
  float: right;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  width: 330px;
  margin: 0 auto;
}
.vue-daterange-picker {
  min-width: auto;
}
</style>
